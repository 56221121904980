export const templateHeadRow = [
    
    { id: "like", label: "Like", width: 85, align: "center" },
    { id: "retweet", label: "Repost", width: 85, align: "center" },
    { id: "quote", label: "Quote", width: 85, align: "center" },
    { id: "reply", label: "Reply", width: 60, align: "center" },

    // { id: "follow", label: "Follow", width: 80, align: "center" },
    // { id: "edit", label: "", width: 80, align: "center" },
  ];
  