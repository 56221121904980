import styled from "styled-components";
export const LogoContainer = styled.div`
  padding-top: 5vh;
  padding-bottom: 3vh;
  align-items: center;
  display: flex;
  justify-content: center;

  & svg:hover {
		// cursor: pointer;
	}
`;
