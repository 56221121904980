export const invoiceHeadRow = [
    { id: "invoiceNo", label: "Invoice No.", width: 60, align: "center" },
    { id: "invoiceDate", label: "Invoice Date", width: 85, align: "center" },
    { id: "invoiceType", label: "Invoice Type", width: 85, align: "center" },
    { id: "invoiceAmount", label: "Invoice Amount", width: 85, align: "center" },
    { id: "invoiceHash", label: "Invoice Hash", width: 80, align: "center" },
    { id: "nodeLink", label: "Mirror Node Link", width: 60, align: "center" },
    { id: "download", label: "Download", width: 60, align: "center" },
  ];
  
  export const invoiceData = [ 
    {
        invoiceNo: 1,
        invoiceDate: "10 Dec 2020",
        invoiceType: "Topup",
        invoiceAmount: 15000,
        invoiceHash: "1200h",
        nodeLink: "Link",
        download: "",
  },
    {
        invoiceNo: 2,
        invoiceDate: "15 Dec 2020",
        invoiceType: "Reimburse",
        invoiceAmount: 14000,
        invoiceHash: "1250h",
        nodeLink: "Link",
        download: "",
  },
    {
        invoiceNo: 3,
        invoiceDate: "20 Dec 2021",
        invoiceType: "Topup",
        invoiceAmount: 13000,
        invoiceHash: "1300h",
        nodeLink: "Link",
        download: "",
  },
    {
        invoiceNo: 4,
        invoiceDate: "10 Dec 2022",
        invoiceType: "Reimburse",
        invoiceAmount: 12000,
        invoiceHash: "1350h",
        nodeLink: "Link",
        download: "",
  },
    {
        invoiceNo: 1,
        invoiceDate: "10 Dec 2020",
        invoiceType: "Topup",
        invoiceAmount: 15000,
        invoiceHash: "1200h",
        nodeLink: "Link",
        download: "",
  },
    {
        invoiceNo: 2,
        invoiceDate: "15 Dec 2020",
        invoiceType: "Reimburse",
        invoiceAmount: 14000,
        invoiceHash: "1250h",
        nodeLink: "Link",
        download: "",
  },
    {
        invoiceNo: 3,
        invoiceDate: "20 Dec 2021",
        invoiceType: "Topup",
        invoiceAmount: 13000,
        invoiceHash: "1300h",
        nodeLink: "Link",
        download: "",
  },
    {
        invoiceNo: 4,
        invoiceDate: "10 Dec 2022",
        invoiceType: "Reimburse",
        invoiceAmount: 12000,
        invoiceHash: "1350h",
        nodeLink: "Link",
        download: "",
  },
]

export const transactionHeadRow = [
    { id: "tranNo", label: "Card No.", width: 60, align: "center" },
    { id: "tranId", label: "Transaction ID", width: 85, align: "center" },
    { id: "senderHandle", label: "Sender T Handle", width: 85, align: "center" },
    { id: "receiverHandle", label: "Receiver T Handle", width: 85, align: "center" },
    { id: "amountSent", label: "Amount Sent", width: 85, align: "center" },
    { id: "actionTaken", label: "Action Taken", width: 85, align: "center" },
    { id: "tranHash", label: "Transaction Hash", width: 80, align: "center" },
    { id: "nodeLink", label: "Mirror Node Link", width: 60, align: "center" },
    { id: "download", label: "Download", width: 60, align: "center" },
  ];
  
  export const transactionData = [ 
    {
      tranNo: 1,
      tranId: 1252,
      senderHandle:"10 Jan 2022",
      receiverHandle: 1500,
      amountSent: 1500,
      actionTaken: "Like",
      tranHash: "1200h",
      nodeLink: "Link",
      download:"",
  },
    {
      tranNo: 2,
      tranId: 1342,
      senderHandle:"10 Jan 2022",
      receiverHandle: 1550,
      amountSent: 1300,
      actionTaken: "Follow",
      tranHash: "1200h",
      nodeLink: "Link",
      download:"",
  },
    {
      tranNo: 3,
      tranId: 12342,
      senderHandle:"20 Jan 2022",
      receiverHandle: 1350,
      amountSent: 1250,
      actionTaken: "Retweet",
      tranHash: "1100h",
      nodeLink: "Link",
      download:"",
  },
    {
      tranNo: 4,
      tranId: 1252,
      senderHandle:"10 Jan 2022",
      receiverHandle: 1500,
      amountSent: 1500,
      actionTaken: "Like",
      tranHash: "1200h",
      nodeLink: "Link",
      download:"",
  },
    {
      tranNo: 5,
      tranId: 1342,
      senderHandle:"10 Jan 2022",
      receiverHandle: 1550,
      amountSent: 1300,
      actionTaken: "Follow",
      tranHash: "1200h",
      nodeLink: "Link",
      download:"",
  },
    {
      tranNo: 6,
      tranId: 12342,
      senderHandle:"20 Jan 2022",
      receiverHandle: 1350,
      amountSent: 1250,
      actionTaken: "Retweet",
      tranHash: "1100h",
      nodeLink: "Link",
      download:"",
  },
    {
      tranNo: 7,
      tranId: 1342,
      senderHandle:"10 Jan 2022",
      receiverHandle: 1550,
      amountSent: 1300,
      actionTaken: "Follow",
      tranHash: "1200h",
      nodeLink: "Link",
      download:"",
  },
    {
      tranNo: 8,
      tranId: 12342,
      senderHandle:"20 Jan 2022",
      receiverHandle: 1350,
      amountSent: 1250,
      actionTaken: "Retweet",
      tranHash: "1100h",
      nodeLink: "Link",
      download:"",
  },
    
]